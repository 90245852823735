import React from 'react';
import { useParams } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';

const GET_PAGE = gql`
  query page($slug: String!) {
    page(slug: $slug) {
      title
      content
    }
  }
`;

export function PageDetail(props: { slug?: string }) {
  let { slug } = useParams();

  slug = props.slug ? props.slug : slug;

  const { loading, error, data } = useQuery(GET_PAGE, {
    variables: { slug: slug },
  });

  if (loading) return <p>Nahrává se...</p>;
  if (error) return <p>Chyba: {error.message}</p>;

  const page = data.page;
  if (page === null) {
    return <p>Chyba při načítání dat.</p>;
  }

  return (
    <div className="page-detail">
      <h5>{page.title}</h5>
      <div className="page-content">
        <span
          className="align-top"
          dangerouslySetInnerHTML={{
            __html: page.content,
          }}
        ></span>
      </div>
    </div>
  );
}
