import React, { MouseEventHandler, ReactNode, useContext } from 'react';
import { createRef } from 'react';
import { UserContext } from '../contexts/UserContext';

type AuthenticatedLinkProps = {
  url: string;
  filename: string;
  children: ReactNode;
};

export function AuthenticatedLink(props: AuthenticatedLinkProps) {
  const { userToken } = useContext(UserContext);

  const link = createRef<HTMLAnchorElement>();

  const handleAction: MouseEventHandler<HTMLAnchorElement> = () => {
    if (link.current?.href) {
      return;
    }
    fetch(props.url, {
      headers: { Authorization: `Bearer ${userToken}` },
      cache: 'no-cache',
    })
      .then((result) => result.blob())
      .then((blob) => {
        const href = window.URL.createObjectURL(blob);
        if (link.current) {
          link.current.href = href;
          link.current.click();
        }
      });
  };

  return (
    <>
      <a
        className="btn btn-sm btn-primary text-white"
        role="button"
        ref={link}
        download={props.filename}
        onClick={handleAction}
      >
        {props.children}
      </a>
    </>
  );
}
