import React from 'react';
import { gql, useQuery } from '@apollo/client';
import GiftCertificateLink from './GiftCertificateLink';

export interface ITransactionsSummary {
  year: number;
  value: number;
  certificate?: {
    id: string;
    downloadAllowed: boolean;
  };
}

interface IResponse {
  myTransactionsSummary: ITransactionsSummary[];
}

const GET_TX_SUMMARY = gql`
  query MyTransactionsSummary {
    myTransactionsSummary {
      year
      value
      certificate {
        id
        downloadAllowed
      }
    }
  }
`;

export default function UserTransactions() {
  const { loading, error, data } = useQuery<IResponse>(GET_TX_SUMMARY);

  if (loading) return <p>Nahrává se...</p>;
  if (error) return <p>Chyba: {error.message}</p>;
  if (!data) {
    return <p>Chyba při načítání dat.</p>;
  }
  const transactionsSummary = data.myTransactionsSummary;

  return (
    <>
      <h4>Potvrzení o daru</h4>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Za rok</th>
            <th scope="col">Částka</th>
            <th scope="col">Potvrzení o daru</th>
          </tr>
        </thead>
        <tbody>
          {transactionsSummary.map((summary) => (
            <tr key={summary.year}>
              <th scope="row">{summary.year}</th>
              <td>{summary.value}&nbsp;Kč</td>
              <td>
                <GiftCertificateLink summary={summary} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}
