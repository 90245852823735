import React, { useState } from 'react';
import 'easymde/dist/easymde.min.css';
import { Button, Form, InputGroup, Modal } from 'react-bootstrap';
import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { IItemNode } from './list';
import { statusToString, txTypeToString } from './helpers';

const GET_USERS_LIST = gql`
  query UsersListForTx {
    usersList {
      edges {
        node {
          id
          mailingAddress {
            name
          }
          email
        }
      }
    }
    adminChildList {
      id
      fullNumber
      name
    }
  }
`;

export interface IUsersList {
  usersList: {
    edges: {
      node: {
        id: string;
        mailingAddress?: {
          name: string;
        };
        email: string;
      };
    }[];
  };
  adminChildList: {
    id: string;
    fullNumber: string;
    name: string;
  }[];
}

const TX_MUTATION = gql`
  mutation CreateOrUpdateUserTx(
    $id: String
    $timestamp: DateTime!
    $type: String!
    $userId: String
    $childId: String
    $value: Int!
    $currency: String!
    $description: String!
    $status: String!
  ) {
    createOrUpdateTransaction(
      data: {
        id: $id
        timestamp: $timestamp
        type: $type
        userId: $userId
        childId: $childId
        value: $value
        currency: $currency
        description: $description
        status: $status
      }
    ) {
      id
    }
  }
`;

const DELETE_TX_MUTATION = gql`
  mutation DeleteUserTransaction($id: String!) {
    deleteTransaction(data: { id: $id }) {
      success
    }
  }
`;

const GET_USER_ID_FROM_CHILD = gql`
  query AdminAdoptionInfo($childId: String!) {
    adminAdoptionInfo(data: $childId)
  }
`;

interface IResponseUserId {
  adminAdoptionInfo: string | null;
}

interface IChangeUserTransactionResponse {
  ChangeUserTransaction: IItemNode;
}

export interface IResponseModel {
  success: boolean;
}

interface MutationTxInput {
  id?: string;
  timestamp: Date;
  type: string;
  source: string;
  status: string;
  userId: string | null;
  childId: string | null;
  value: number;
  currency: string;
  description: string;
}

const defaultTx = {
  timestamp: new Date(),
  type: 'OTHER',
  source: 'MANUAL',
  status: 'ACCOUNTED',
  userId: null,
  childId: null,
  value: 0,
  currency: 'CZK',
  description: '',
} as MutationTxInput;

export default function BackofficeTransactionDetail(props: {
  title: string;
  tx?: MutationTxInput;
}) {
  const [transaction, setTransaction] = useState(props.tx ?? defaultTx);

  const [show, setShow] = useState(false);

  const {
    loading,
    error,
    data: response,
  } = useQuery<IUsersList>(GET_USERS_LIST);

  const [transactionMutation] = useMutation<IChangeUserTransactionResponse>(
    TX_MUTATION,
    {
      variables: transaction,
      onCompleted: () => {
        setShow(false);
      },
      refetchQueries: ['UserTransactions'],
    },
  );

  const [deleteTxMutation] = useMutation<IResponseModel>(DELETE_TX_MUTATION, {
    variables: {
      id: transaction.id,
    },
    onCompleted: () => {
      setShow(false);
    },
    refetchQueries: ['UserTransactions'],
  });

  const [autoUpdateUserId] = useLazyQuery<IResponseUserId>(
    GET_USER_ID_FROM_CHILD,
    {
      onCompleted: (data) => {
        setTransaction({ ...transaction, userId: data.adminAdoptionInfo });
      },
    },
  );

  const handleSave = async () => {
    await transactionMutation();
  };

  const handleUserAutoSelect = async () => {
    console.log('Automatický výběr rodiče.');
    await autoUpdateUserId({
      variables: {
        childId: transaction.childId,
      },
    });
  };

  const handleClose = () => setShow(false);

  const handleDelete = async () => {
    await deleteTxMutation();
    setShow(false);
  };

  const handleShow = () => setShow(true);

  if (loading) return <p>Nahrává se...</p>;
  if (error) return <p>Chyba: {error.message}</p>;

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        {props.title}
      </Button>

      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Úprava transakce</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <InputGroup className="mb-3">
              <InputGroup.Text>Zaúčtováno</InputGroup.Text>
              <Form.Control
                type="datetime-local"
                onChange={(event) => {
                  setTransaction({
                    ...transaction,
                    timestamp: new Date(event.target.value),
                  });
                }}
                value={new Date(
                  new Date(transaction.timestamp).getTime() + 120 * 60 * 1000,
                )
                  .toISOString()
                  .slice(0, 19)}
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text>Typ</InputGroup.Text>
              <Form.Select
                onChange={(event) => {
                  setTransaction({ ...transaction, type: event.target.value });
                }}
              >
                <option
                  value="ADOPTION"
                  selected={transaction.type === 'ADOPTION'}
                >
                  {txTypeToString('ADOPTION')}
                </option>
                <option
                  value="DONATION"
                  selected={transaction.type === 'DONATION'}
                >
                  {txTypeToString('DONATION')}
                </option>
                <option value="GIFT" selected={transaction.type === 'GIFT'}>
                  {txTypeToString('GIFT')}
                </option>
                <option value="OTHER" selected={transaction.type === 'OTHER'}>
                  {txTypeToString('OTHER')}
                </option>
              </Form.Select>
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text>Dítě</InputGroup.Text>
              <Form.Select
                onChange={(event) => {
                  setTransaction({
                    ...transaction,
                    childId:
                      event.target.value === 'none' ? null : event.target.value,
                  });
                }}
              >
                <option
                  key="none"
                  value="none"
                  selected={transaction.childId === null}
                >
                  {' '}
                  -- žádné --{' '}
                </option>
                {response?.adminChildList?.map((child) => (
                  <option
                    key={child.id}
                    value={child.id}
                    selected={child.id === transaction.childId}
                  >
                    {child.fullNumber} &minus; {child.name}
                  </option>
                ))}
              </Form.Select>
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text>Rodič</InputGroup.Text>
              <Form.Select
                onChange={(event) => {
                  setTransaction({
                    ...transaction,
                    userId:
                      event.target.value === 'none' ? null : event.target.value,
                  });
                }}
              >
                <option
                  key="none"
                  value="none"
                  selected={transaction.userId === null}
                >
                  {' '}
                  -- žádný --{' '}
                </option>
                {response?.usersList?.edges.map((userEdge) => (
                  <option
                    key={userEdge.node.id}
                    value={userEdge.node.id}
                    selected={userEdge.node.id === transaction.userId}
                  >
                    {userEdge.node.email}{' '}
                    {userEdge.node.mailingAddress?.name && (
                      <span>&minus; {userEdge.node.mailingAddress?.name}</span>
                    )}
                  </option>
                ))}
              </Form.Select>
              <Button
                variant="primary"
                disabled={transaction.childId === null}
                onClick={handleUserAutoSelect}
              >
                Doplnit automaticky
              </Button>
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text>Hodnota v CZK</InputGroup.Text>
              <Form.Control
                type="number"
                onChange={(event) => {
                  setTransaction({
                    ...transaction,
                    value: parseInt(event.target.value),
                  });
                }}
                value={transaction.value}
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text>Stav</InputGroup.Text>
              <Form.Select
                onChange={(event) => {
                  setTransaction({
                    ...transaction,
                    status: event.target.value,
                  });
                }}
              >
                <option
                  value="UNACCOUNTED"
                  selected={transaction.status === 'UNACCOUNTED'}
                >
                  {statusToString('UNACCOUNTED')}
                </option>
                <option
                  value="ACCOUNTED"
                  selected={transaction.status === 'ACCOUNTED'}
                >
                  {statusToString('ACCOUNTED')}
                </option>
                <option
                  value="CANCELED"
                  selected={transaction.status === 'CANCELED'}
                >
                  {statusToString('CANCELED')}
                </option>
              </Form.Select>
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text>Popis</InputGroup.Text>
              <Form.Control
                as="textarea"
                rows={3}
                type="text"
                onChange={(event) => {
                  setTransaction({
                    ...transaction,
                    description: event.target.value,
                  });
                }}
                value={transaction.description}
              />
            </InputGroup>
          </>
        </Modal.Body>
        <Modal.Footer>
          {transaction.source === 'MANUAL' && (
            <Button variant="danger" onClick={handleDelete}>
              Smazat
            </Button>
          )}
          <Button variant="secondary" onClick={handleClose}>
            Zavřít
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Uložit změny
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
