import React from 'react';
import { IAddress } from './Address';

export default function AddressPlain(props: { address: IAddress }) {
  const title =
    props.address.addressType === 'MAILING' ? 'Korespondenční' : 'Smluvní';

  return (
    <>
      <h6>{title}</h6>
      {props.address.isSameAsMailing && (
        <p>Adresa je stejná jako korespondenční.</p>
      )}
      {!props.address.isSameAsMailing && (
        <ul>
          <li>
            <strong>Jméno/název firmy:</strong> {props.address.name}
          </li>
          <li>
            <strong>Ulice, číslo domu:</strong> {props.address.street}
          </li>
          <li>
            <strong>PSČ:</strong> {props.address.zip}
          </li>
          <li>
            <strong>Obec:</strong> {props.address.city}
          </li>
          <li>
            <strong>Stát:</strong> {props.address.state}
          </li>
          <li>
            <strong>Telefonní číslo:</strong> {props.address.phone}
          </li>
        </ul>
      )}
    </>
  );
}
