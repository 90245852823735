import { Link } from 'react-router-dom';
import React from 'react';
import { useQuery, gql } from '@apollo/client';

const GET_ADOPTION_OFFER = gql`
  query {
    randomAdoptionOffer {
      name
      fullNumber
      mainPhoto
    }
  }
`;

export default function AdoptionOffer() {
  const { loading, error, data } = useQuery(GET_ADOPTION_OFFER, {
    pollInterval: 20000,
  });

  if (loading) return <p>Nahrává se...</p>;
  if (error) return <p>Chyba : {error.message}</p>;
  if (data.randomAdoptionOffer === null) return <></>;

  return (
    <div
      className="menu-block d-none d-md-block"
      style={{ position: 'relative' }}
    >
      <img
        src="/images/okraj.gif"
        className="adoption-offer"
        style={{
          position: 'relative',
          width: '100%',
        }}
        alt=""
      ></img>
      <div
        style={{
          position: 'absolute',
          top: '-3%',
          width: '100%',
        }}
      >
        <Link to={'/child/' + data.randomAdoptionOffer.fullNumber}>
          <img
            style={{
              maxWidth: '70%',
              maxHeight: '70%',
              margin: '15%',
              border: 'solid 1px black',
            }}
            className="adoption-offer-image"
            src={
              'https://procontact.fra1.digitaloceanspaces.com/photos/' +
              data.randomAdoptionOffer.mainPhoto
            }
            alt=""
          />
        </Link>
      </div>
      <div style={{ clear: 'both' }}></div>
    </div>
  );
}
