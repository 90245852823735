import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { SubmitHandler, useForm } from 'react-hook-form';
import Form from 'react-bootstrap/Form';
import ReCAPTCHA from 'react-google-recaptcha';
import { RECAPTCHA_SITE_KEY } from '../../constants';
import { ErrorMessage } from '@hookform/error-message';

type Inputs = {
  email: string;
  password: string;
  password2: string;
  phone?: string;
};

interface RegistrationResponse {
  userRegistration: {
    success: boolean;
    message: string;
  };
}

const REGISTRATION_MUTATION = gql`
  mutation UserRegistration(
    $email: String!
    $password: String!
    $phone: String
    $captchaToken: String!
  ) {
    userRegistration(
      data: { email: $email, password: $password, phone: $phone }
      captchaToken: $captchaToken
    ) {
      success
      message
    }
  }
`;

export function UserRegistration() {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
  } = useForm<Inputs>({ criteriaMode: 'all' });

  const [captchaToken, setCaptchaToken] = useState<string | null>('');

  const [message, setMessage] = useState<string | null>(null);
  const [messageLevel, setMessageLevel] = useState<string>('success');

  const [registrationMutation] = useMutation<RegistrationResponse>(
    REGISTRATION_MUTATION,
    {
      onCompleted: (response) => {
        if (!response.userRegistration.success) {
          setMessage(response.userRegistration.message);
          setMessageLevel('danger');
        } else {
          setMessage(
            'Uživatelský účet byl založen. Pokračujte dále dle instrukcí v emailu.',
          );
          setMessageLevel('success');
          reset();
        }
      },
    },
  );

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    if (captchaToken && data.email)
      await registrationMutation({
        variables: {
          captchaToken,
          email: data.email,
          phone: data.phone,
          password: data.password,
        },
      });
  };

  return (
    <div>
      <h3>Registrace</h3>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="container">
          <div className="row">
            <div className="form-group col col-12">
              {message && (
                <div
                  className={`alert alert-${messageLevel} bg-${messageLevel} text-white`}
                  role="alert"
                >
                  {message}
                </div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="form-group col col-4  col-sm-12">
              <Form.Label>E-mail:</Form.Label>
              <Form.Control
                {...register('email', {
                  required: 'Vyplňte prosím toto pole.',
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: 'Zadaná e-mailová adresa není validní.',
                  },
                })}
                type="input"
                name="email"
                placeholder="Zadejte váš e-mail"
                className="mb-2"
              />
              <ErrorMessage
                errors={errors}
                name="email"
                render={({ messages }) =>
                  messages &&
                  Object.entries(messages).map(([type, message]) => (
                    <div
                      key={type}
                      className={`alert alert-danger bg-danger text-white`}
                      role="alert"
                    >
                      {message}
                    </div>
                  ))
                }
              />
            </div>
          </div>
          <div className="row">
            <div className="form-group col col-4  col-sm-12">
              <Form.Label>Heslo:</Form.Label>
              <Form.Control
                {...register('password', {
                  required: 'Vyplňte prosím toto pole.',
                  validate: (val: string) => {
                    if (val.length < 8) {
                      return 'Heslo musí obsahovat alespoň 8 znaků.';
                    }
                  },
                })}
                placeholder="Zadejte vaše heslo"
                type="password"
                name="password"
                className="mb-2"
              />
              <ErrorMessage
                errors={errors}
                name="password"
                render={({ messages }) =>
                  messages &&
                  Object.entries(messages).map(([type, message]) => (
                    <div
                      key={type}
                      className={`alert alert-danger bg-danger text-white`}
                      role="alert"
                    >
                      {message}
                    </div>
                  ))
                }
              />
            </div>
          </div>
          <div className="row">
            <div className="form-group col col-4  col-sm-12">
              <Form.Label>Heslo (kontrola):</Form.Label>
              <Form.Control
                {...register('password2', {
                  required: 'Vyplňte prosím toto pole.',
                  validate: (val: string) => {
                    if (watch('password') != val) {
                      return 'Hesla se neshodují.';
                    }
                  },
                })}
                placeholder="Zadejte heslo znovu"
                type="password"
                name="password2"
                className="mb-2"
              />
              <ErrorMessage
                errors={errors}
                name="password2"
                render={({ messages }) =>
                  messages &&
                  Object.entries(messages).map(([type, message]) => (
                    <div
                      key={type}
                      className={`alert alert-danger bg-danger text-white`}
                      role="alert"
                    >
                      {message}
                    </div>
                  ))
                }
              />
            </div>
          </div>
          <div className="row">
            <div className="form-group col col-4  col-sm-12">
              <Form.Label>Telefonní číslo:</Form.Label>
              <Form.Control
                {...register('phone', {
                  required: false,
                  pattern: {
                    value: /^(\+\d{12})|(\d{9})$/g,
                    message:
                      'Zadané telefonní číslo musí vyhovovat formátu například +420123456789 nebo 123456789.',
                  },
                })}
                type="input"
                name="phone"
                placeholder="Zadejte vaše telefonní číslo"
                className="mb-2"
              />
              <ErrorMessage
                errors={errors}
                name="phone"
                render={({ messages }) =>
                  messages &&
                  Object.entries(messages).map(([type, message]) => (
                    <div
                      key={type}
                      className={`alert alert-danger bg-danger text-white`}
                      role="alert"
                    >
                      {message}
                    </div>
                  ))
                }
              />
            </div>
          </div>
          <div className="row">
            <p>
              Vážení dárci/adoptivní rodiče, v souvislosti s nařízeními o
              ochraně osobních údajů Vás informujeme, že registrací na našich
              webových stránkách a vyplněním registračního formuláře udělujete
              organizaci pro-Contact, z.s., se sídlem &quot;Karlovo nám.5, Praha
              2&quot; (reg. pod.č.j. VS/1.1/57615/04-R) souhlas, aby ve smyslu
              zákona č. 101/2000 Sb., o ochraně osobních údajů a v souladu s
              Nařízením Evropského parlamentu a Rady (EU) 2016/679, zpracovávala
              následující osobní údaje: jméno, příjmení a/nebo název
              společnosti, e-mail, telefonní číslo, adresa/sídlo, které jsou
              potřeba k vystavení darovací smlouvy a následně k průběžné
              komunikaci v rámci realizace Projektu adopce afrických dětí na
              dálku.
            </p>
          </div>
          <div className="row">
            <div className="form-group col col-4 col-sm-12">
              <ReCAPTCHA
                sitekey={RECAPTCHA_SITE_KEY}
                onChange={(token) => setCaptchaToken(token)}
              />
            </div>
          </div>
          <div className="row">
            <div className="form-group col col-4 col-sm-12">
              <input
                type="submit"
                className="btn btn-primary mb-2 mt-2"
                value="Odeslat"
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
