import React, { useEffect, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';

interface EmailVerifyResponse {
  verifyEmailChange: {
    success: boolean;
    message: string;
  };
}

const EMAIL_VERIFY_MUTATION = gql`
  mutation VerifyEmailChange($token: String!) {
    verifyEmailChange(token: $token) {
      success
      message
    }
  }
`;
export function EmailVerify() {
  const { token } = useParams();

  const [inProgress, setInProgress] = useState<boolean>(true);

  const [error, setError] = useState<string | null>(null);

  const [emailVerifyMutation] = useMutation<EmailVerifyResponse>(
    EMAIL_VERIFY_MUTATION,
    {
      onError: () => {
        setError('V průběhu ověřování emailové adresy nastala chyba.');
      },
    },
  );

  useEffect(() => {
    emailVerifyMutation({
      variables: {
        token,
      },
    }).then(() => {
      setInProgress(false);
    });
  }, []);

  return (
    <div>
      <h3>Ověření emailové adresy</h3>

      {error && (
        <div className={`alert alert-danger bg-danger text-white`} role="alert">
          {error}
        </div>
      )}

      {inProgress && (
        <div
          className={`alert alert-warning bg-warning text-white`}
          role="alert"
        >
          Ověření právě probíhá...
        </div>
      )}

      {!inProgress && !error && (
        <div
          className={`alert alert-success bg-success text-white`}
          role="alert"
        >
          Ověření proběhlo v pořádku.
        </div>
      )}
    </div>
  );
}
