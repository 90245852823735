import { IAttachment } from '../ChildDetail';
import React from 'react';
import { preparePhotoUrl } from '../../tools';

export default function LightGalleryItem(props: { attachment: IAttachment }) {
  let thumbnail = preparePhotoUrl(props.attachment.filename);
  let target = preparePhotoUrl(props.attachment.filename);
  if (props.attachment.type === 'link') {
    let parts = props.attachment.filename.split('?v=');
    let ytHash = parts.pop();
    if (parts.length <= 1) {
      parts = props.attachment.filename.split('/');
      ytHash = parts.pop();
    }
    thumbnail = `https://img.youtube.com/vi/${ytHash}/maxresdefault.jpg`;
    target = props.attachment.filename;
  }
  return (
    <a key={props.attachment.id} href={target}>
      <img
        style={{
          maxWidth: '200px',
          maxHeight: '200px',
          width: 'auto',
          height: 'auto',
          margin: '3px 5px 3px 3px',
        }}
        src={thumbnail}
        alt=""
      />
    </a>
  );
}
