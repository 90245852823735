import React, { useContext, useState } from 'react';
import 'easymde/dist/easymde.min.css';
import { Button, Form, Modal } from 'react-bootstrap';
import { gql, useMutation, useQuery } from '@apollo/client';
import { IChildDetail } from './ChildDetail';
import { UserContext } from '../contexts/UserContext';
import LightGallery from 'lightgallery/react';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import { lgLicenseKey, preparePhotoUrl } from '../tools';
import { IAddress } from '../pages/profile/Address';
import AddressPlain from '../pages/profile/AddressPlain';

const GET_PAYMENT_INTERVALS = gql`
  query {
    paymentIntervals {
      id
      treaty
    }
    myAddresses {
      mailingAddress {
        addressType
        name
        street
        city
        zip
        state
        phone
      }
      contractAddress {
        addressType
        name
        street
        city
        zip
        state
        phone
        isSameAsMailing
      }
    }
  }
`;

const PRERESERVE_CHILD = gql`
  mutation preReserveChild($childNumber: Int!, $paymentIntervalId: String!) {
    preReserveChild(
      childNumber: $childNumber
      paymentIntervalId: $paymentIntervalId
    ) {
      preReserved
    }
  }
`;

interface IPaymentInterval {
  id: string;
  treaty: string;
}

interface IResponse {
  paymentIntervals: IPaymentInterval[];
  myAddresses: { mailingAddress: IAddress; contractAddress: IAddress };
}

interface IMutationResponse {
  preReserveChild: IChildDetail;
}

export default function ChildAdoption(props: { data: IChildDetail }) {
  const [show, setShow] = useState(false);

  const [selectedPaymentIntervalId, setSelectedPaymentIntervalId] =
    useState<string>('');
  const { isLoggedIn } = useContext(UserContext);

  const {
    loading,
    error,
    data: queryResponse,
  } = useQuery<IResponse>(GET_PAYMENT_INTERVALS);

  const [preReserveMutation] = useMutation<IMutationResponse>(PRERESERVE_CHILD);

  const handleAdopt = async () => {
    const paymentIntervalId =
      selectedPaymentIntervalId || queryResponse?.paymentIntervals[0].id;
    console.log(
      'HandleAdopt called',
      selectedPaymentIntervalId || queryResponse?.paymentIntervals[0].id,
      props.data,
    );
    await preReserveMutation({
      variables: {
        childNumber: Number.parseInt(String(props.data.number)),
        paymentIntervalId,
      },
      refetchQueries: ['child'],
    });
    setShow(false);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const selectedPaymentIntervalChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setSelectedPaymentIntervalId(event.target.value);
  };

  if (loading) return <p>Načítám...</p>;
  // TODO unauthorized if (error) return <p>Chyba! ${error.message}</p>;

  if (!isLoggedIn) {
    return (
      <div className="alert alert-info mb-1 mt-1" role="alert">
        Pro adopci toho dítěte se musíte nejdříve{' '}
        <a
          href="/profile/login"
          style={{ color: 'darkgreen', fontWeight: 'bold' }}
        >
          přihlásit
        </a>{' '}
        případně{' '}
        <a
          href="/profile/registration"
          style={{ color: 'darkgreen', fontWeight: 'bold' }}
        >
          zaregistrovat
        </a>
        .
      </div>
    );
  }

  const disabled =
    queryResponse?.myAddresses.mailingAddress.name === '' ||
    queryResponse?.myAddresses.contractAddress.name === '';

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Adoptovat
      </Button>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            Adopce dítěte - {props.data.name} - {props.data.fullNumber}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <LightGallery
              speed={500}
              plugins={[lgThumbnail, lgZoom]}
              licenseKey={lgLicenseKey()}
            >
              <a href={preparePhotoUrl(props.data.mainPhoto)}>
                <img
                  style={{
                    float: 'left',
                    maxWidth: '50',
                    maxHeight: '50',
                    width: '100px',
                    height: 'auto',
                    margin: '3px 5px 3px 3px',
                  }}
                  src={preparePhotoUrl(props.data.mainPhoto)}
                  alt=""
                />
              </a>
            </LightGallery>
            <p
              className="align-top"
              dangerouslySetInnerHTML={{
                __html: props.data.teaser,
              }}
            ></p>
            <div style={{ clear: 'both' }}></div>

            {queryResponse && (
              <>
                <h5>Vaše adresy</h5>
                <AddressPlain
                  address={queryResponse?.myAddresses.mailingAddress}
                ></AddressPlain>
                <AddressPlain
                  address={queryResponse?.myAddresses.contractAddress}
                ></AddressPlain>
                {disabled ? (
                  <div
                    className="alert alert-danger text-center fw-bold mb-1"
                    role="alert"
                  >
                    Pro pokračování je nutné mít vyplněné obě adresy ve{' '}
                    <a href="/profile">vašem profilu</a>.
                  </div>
                ) : (
                  <p>
                    Adresy je možné upravovat ve{' '}
                    <a href="/profile">vašem profilu</a>.
                  </p>
                )}
              </>
            )}
            <Form.Group className="mb-3">
              <Form.Label>Způsob platby adopce</Form.Label>
              <Form.Select autoFocus onChange={selectedPaymentIntervalChange}>
                {queryResponse?.paymentIntervals.map((paymentInterval) => (
                  <option key={paymentInterval.id} value={paymentInterval.id}>
                    {paymentInterval.treaty}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Zavřít
          </Button>
          <Button variant="primary" onClick={handleAdopt} disabled={disabled}>
            Adoptovat toto dítě
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
