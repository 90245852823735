import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { ITransactionsSummary } from './UserTransactions';
import { AuthenticatedLink } from '../../components/AuthenticatedLink';
import { getBackendUrl } from '../../tools';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ContainerFormField } from '../../components/common/ContainerFormField';
import { gql, useMutation } from '@apollo/client';

type Inputs = {
  name: string;
  company?: string;
  address: string;
  icRc?: string;
  dic?: string;
};

interface IResponse {
  createGiftCertificate: {
    success: boolean;
    message: string;
  };
}

const CREATE_GIFT_CERTIFICATE = gql`
  mutation CreateGiftCertificate(
    $year: Int!
    $name: String!
    $company: String
    $address: String!
    $icRc: String
    $dic: String
  ) {
    createGiftCertificate(
      data: {
        year: $year
        name: $name
        company: $company
        address: $address
        icRc: $icRc
        dic: $dic
      }
    ) {
      success
      message
    }
  }
`;

export default function GiftCertificateLink(props: {
  summary: ITransactionsSummary;
}) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
  } = useForm<Inputs>({ criteriaMode: 'all' });

  const [message, setMessage] = useState<string | null>(null);
  const [messageLevel, setMessageLevel] = useState<string>('success');

  const [createGiftCertificateMutation] = useMutation<IResponse>(
    CREATE_GIFT_CERTIFICATE,
    {
      onCompleted: (response) => {
        if (!response.createGiftCertificate.success) {
          setMessage(response.createGiftCertificate.message);
          setMessageLevel('danger');
        } else {
          setShow(false);
        }
      },
      refetchQueries: ['MyTransactionsSummary'],
    },
  );

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    reset();
    setMessage(null);
    setMessageLevel('success');
  };

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    await createGiftCertificateMutation({
      variables: { ...data, year: props.summary.year },
    });
  };

  if (props.summary.year === new Date().getFullYear()) {
    return <></>;
  }

  if (props.summary.certificate && props.summary.certificate.downloadAllowed) {
    return (
      <>
        <AuthenticatedLink
          url={`${getBackendUrl()}/adoption/gift-certificate/${props.summary.certificate.id}`}
          filename={`potvrzeni_o_daru_${props.summary.year}_${props.summary.certificate.id}.pdf`}
        >
          Stáhnout
        </AuthenticatedLink>
      </>
    );
  } else {
    return (
      <>
        <Button variant="primary" onClick={handleShow}>
          Vystavit
        </Button>

        <Modal show={show} onHide={handleClose}>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Modal.Header closeButton>
              <Modal.Title>
                Potvrzení o daru za rok {props.summary.year}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <>
                <div className="container">
                  <div className="row">
                    <div className="form-group col col-12">
                      {message && (
                        <div
                          className={`alert alert-${messageLevel} bg-${messageLevel} text-white`}
                          role="alert"
                        >
                          {message}
                        </div>
                      )}
                    </div>
                  </div>
                  <ContainerFormField
                    name="name"
                    type="input"
                    label="Jméno a příjmení"
                    required={true}
                    errors={errors}
                    register={register}
                  ></ContainerFormField>
                  <ContainerFormField
                    name="company"
                    type="input"
                    label="Název firmy"
                    required={false}
                    errors={errors}
                    register={register}
                  ></ContainerFormField>
                  <ContainerFormField
                    name="address"
                    type="input"
                    label="Adresa"
                    required={true}
                    errors={errors}
                    register={register}
                  ></ContainerFormField>
                  <ContainerFormField
                    name="icRc"
                    type="input"
                    label="IČ/RČ"
                    required={false}
                    errors={errors}
                    register={register}
                  ></ContainerFormField>
                  <ContainerFormField
                    name="dic"
                    type="input"
                    label="DIČ"
                    required={false}
                    errors={errors}
                    register={register}
                  ></ContainerFormField>
                </div>
              </>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Zavřít
              </Button>
              <Button type="submit" variant="primary">
                Vystavit
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      </>
    );
  }
}
