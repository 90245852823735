import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { AuthenticatedLink } from '../../components/AuthenticatedLink';
import { getBackendUrl } from '../../tools';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ContainerFormField } from '../../components/common/ContainerFormField';
import { IAdoption } from './Adoptions';
import { gql, useMutation } from '@apollo/client';

type Inputs = {
  name: string;
};

interface IResponse {
  changeAdoptionCertificateName: {
    success: boolean;
    message: string;
  };
}

const CHANGE_CERTIFICATE_NAME = gql`
  mutation ChangeAdoptionCertificateName($adoptionId: String!, $name: String!) {
    changeAdoptionCertificateName(
      data: { adoptionId: $adoptionId, name: $name }
    ) {
      success
      message
    }
  }
`;

export default function AdoptionCertificateLink(props: {
  adoption: IAdoption;
}) {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<Inputs>({
    criteriaMode: 'all',
  });

  const [message, setMessage] = useState<string | null>(null);
  const [messageLevel, setMessageLevel] = useState<string>('success');

  const [changeCertificateNameMutation] = useMutation<IResponse>(
    CHANGE_CERTIFICATE_NAME,
    {
      onCompleted: (response) => {
        if (!response.changeAdoptionCertificateName.success) {
          setMessage(response.changeAdoptionCertificateName.message);
          setMessageLevel('danger');
        } else {
          setShow(false);
        }
      },
      refetchQueries: ['MyAdoptions'],
    },
  );

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    reset();
    setValue('name', props.adoption.certificateName ?? '');
    setMessage(null);
    setMessageLevel('success');
  };

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    await changeCertificateNameMutation({
      variables: { adoptionId: props.adoption.id, name: data.name },
    });
  };

  return (
    <>
      &nbsp;
      <AuthenticatedLink
        url={`${getBackendUrl()}/adoption/adoption-certificate/${props.adoption.id}`}
        filename={`certifikat_${props.adoption.child.fullNumber}.pdf`}
      >
        Stáhnout
      </AuthenticatedLink>
      &nbsp;
      <Button variant="primary" className="btn-sm" onClick={handleShow}>
        Upravit
      </Button>
      <Modal show={show} onHide={handleClose}>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Modal.Header closeButton>
            <Modal.Title>
              Upravit údaje na certifikátu {props.adoption.child.fullNumber}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <>
              <div className="container">
                <div className="row">
                  <div className="form-group col col-12">
                    {message && (
                      <div
                        className={`alert alert-${messageLevel} bg-${messageLevel} text-white`}
                        role="alert"
                      >
                        {message}
                      </div>
                    )}
                  </div>
                </div>
                <ContainerFormField
                  name="name"
                  type="input"
                  label="Jméno rodiče na certifikátu"
                  required={false}
                  errors={errors}
                  register={register}
                ></ContainerFormField>
              </div>
            </>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Zavřít
            </Button>
            <Button type="submit" variant="primary">
              Upravit
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}
