import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import Form from 'react-bootstrap/Form';
import { gql, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { ErrorMessage } from '@hookform/error-message';

type Inputs = {
  oldPassword?: string;
  newPassword: string;
  newPassword2: string;
};

interface changePasswordResponse {
  changePassword: {
    success: boolean;
    message: string;
  };
}

const CHANGE_PASSWORD_MUTATION = gql`
  mutation ChangePassword(
    $newPassword: String!
    $oldPassword: String
    $token: String
  ) {
    changePassword(
      data: {
        newPassword: $newPassword
        oldPassword: $oldPassword
        token: $token
      }
    ) {
      success
      message
    }
  }
`;
export default function ChangePassword() {
  const { token } = useParams();

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<Inputs>();

  const [message, setMessage] = useState<string | null>(null);
  const [messageLevel, setMessageLevel] = useState<string>('success');

  const [changePasswordMutation] = useMutation<changePasswordResponse>(
    CHANGE_PASSWORD_MUTATION,
    {
      onCompleted: (response) => {
        if (!response.changePassword.success) {
          setMessage(response.changePassword.message);
          setMessageLevel('danger');
        } else {
          setMessage('Vaše heslo bylo v pořádku změněno.');
          setMessageLevel('success');
          reset();
        }
      },
    },
  );

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    if (data.newPassword)
      await changePasswordMutation({
        variables: { ...data, token },
      });
  };

  return (
    <div>
      <h3>Změna hesla</h3>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="container">
          <div className="row">
            <div className="form-group col col-12">
              {message && (
                <div
                  className={`alert alert-${messageLevel} bg-${messageLevel} text-white`}
                  role="alert"
                >
                  {message}
                </div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="form-group col col-4  col-sm-12">
              <Form.Label>Nové heslo:</Form.Label>
              <Form.Control
                {...register('newPassword', {
                  required: 'Vyplňte prosím toto pole.',
                  validate: (val: string) => {
                    if (val.length < 8) {
                      return 'Heslo musí obsahovat alespoň 8 znaků.';
                    }
                  },
                })}
                type="password"
                name="newPassword"
                className="mb-2"
              />
              <ErrorMessage
                errors={errors}
                name="newPassword"
                render={({ message }) => (
                  <div
                    className={`alert alert-danger bg-danger text-white`}
                    role="alert"
                  >
                    {message}
                  </div>
                )}
              />
            </div>
          </div>
          <div className="row">
            <div className="form-group col col-4  col-sm-12">
              <Form.Label>Nové heslo (kontrola):</Form.Label>
              <Form.Control
                {...register('newPassword2', {
                  required: 'Vyplňte prosím toto pole.',
                  validate: (val: string) => {
                    if (watch('newPassword') != val) {
                      return 'Hesla se neshodují.';
                    }
                  },
                })}
                type="password"
                name="newPassword2"
                className="mb-2"
              />
              <ErrorMessage
                errors={errors}
                name="newPassword2"
                render={({ message }) => (
                  <div
                    className={`alert alert-danger bg-danger text-white`}
                    role="alert"
                  >
                    {message}
                  </div>
                )}
              />
            </div>
          </div>
          <div className="row">
            <div className="form-group col col-4 col-sm-12">
              <input
                type="submit"
                className="btn btn-primary mb-2 mt-2"
                value="Odeslat"
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
