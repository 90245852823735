import React from 'react';

export function ItemList({ label, value }: { label: string; value: string }) {
  if (!value) {
    return <></>;
  }
  return (
    <li key={label}>
      <strong>{label}:</strong> {value}
    </li>
  );
}
