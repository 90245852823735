import React, { useCallback, useMemo, useState } from 'react';
import SimpleMdeReact from 'react-simplemde-editor';
import SimpleMDE from 'easymde';
import 'easymde/dist/easymde.min.css';
import { Button, Form, Modal } from 'react-bootstrap';
import { IEmailTemplate } from './EmailTemplates';
import { gql, useMutation } from '@apollo/client';

const CHANGE_EMAIL_TEMPLATE = gql`
  mutation ChangeEmailTemplate(
    $id: String!
    $subject: String!
    $body: String!
  ) {
    changeEmailTemplate(data: { id: $id, subject: $subject, body: $body }) {
      id
    }
  }
`;

interface IChangeEmailTemplateResponse {
  ChangeEmailTemplate: IEmailTemplate;
}

export default function EmailTemplateDetail(props: IEmailTemplate) {
  const [body, setBody] = useState(props.body);
  const [subject, setSubject] = useState(props.subject);

  const [show, setShow] = useState(false);

  const [changeEmailTemplateMutation] =
    useMutation<IChangeEmailTemplateResponse>(CHANGE_EMAIL_TEMPLATE, {
      variables: {
        id: props.id,
        subject,
        body,
      },
      onCompleted: () => {
        setShow(false);
      },
      refetchQueries: ['EmailTemplates'],
    });

  const handleSave = async () => {
    await changeEmailTemplateMutation();
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const onChangeBody = useCallback((value: string) => {
    setBody(value);
  }, []);

  const onChangeSubject = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSubject(event.target.value);
  };

  const editorOptions = useMemo(() => {
    return {
      spellChecker: false,
      status: false,
      sideBySideFullscreen: false,
    } as SimpleMDE.Options;
  }, []);

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Upravit
      </Button>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{props.type}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <Form.Group className="mb-3">
              <Form.Label>Předmět</Form.Label>
              <Form.Control
                type="text"
                onChange={onChangeSubject}
                value={subject}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Tělo</Form.Label>
              <SimpleMdeReact
                value={body}
                onChange={onChangeBody}
                options={editorOptions}
              />
            </Form.Group>
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Zavřít
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Uložit změny
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
