import React, { useContext } from 'react';
import { UserContext } from '../../contexts/UserContext';

export default function StopImpersonationBlock() {
  const { username, userToken, adminToken, stopImpersonate } =
    useContext(UserContext);

  if (adminToken && userToken && stopImpersonate) {
    return (
      <div className="row" style={{ backgroundColor: 'red' }}>
        <div className="col">
          <div className="p-2 pt-0 pb-0">
            Jsi uživatel <strong>admin</strong> převtělený do uživatele{' '}
            <strong>{username}</strong>. Zde můžeš převtělení
            <button
              className="btn btn-sm btn-primary m-1"
              onClick={() => stopImpersonate()}
            >
              ukončit
            </button>
            .
          </div>
        </div>
      </div>
    );
  }

  return <></>;
}
