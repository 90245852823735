import React, { Fragment } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import { formatDate, preparePhotoUrl } from '../../tools';
import AdoptionCertificateLink from './AdoptionCertificateLink';

const GET_MY_ADOPTIONS = gql`
  query MyAdoptions {
    myAdoptions {
      id
      child {
        name
        fullNumber
        mainPhoto
      }
      paymentInterval {
        treaty
      }
      nextPayment
      certificateName
    }
  }
`;

export interface IAdoption {
  id: string;
  child: {
    name: string;
    fullNumber: string;
    mainPhoto: string;
  };
  paymentInterval: {
    treaty: string;
  };
  nextPayment: string;
  certificateName?: string;
}

export default function Adoptions() {
  const { loading, error, data } = useQuery(GET_MY_ADOPTIONS);

  if (loading) return <p>Nahrává se...</p>;
  if (error) return <p>Chyba: {error.message}</p>;

  const adoptions = data.myAdoptions;
  if (adoptions === null) {
    return <p>Chyba při načítání dat.</p>;
  }

  return (
    <>
      <h5>Vaše adopce</h5>
      <div className="container">
        {adoptions.map((adoption: IAdoption) => (
          <Fragment key={adoption.id}>
            <div className="row bg-light m-2 rounded text-black">
              <div className="col col-4 col-sm-3">
                <Link to={'/child/' + adoption.child.fullNumber}>
                  <img
                    className="rounded"
                    src={preparePhotoUrl(adoption.child.mainPhoto)}
                    alt=""
                    style={{
                      maxHeight: '11rem',
                      maxWidth: '13rem',
                      objectFit: 'contain',
                    }}
                  />
                </Link>
              </div>
              <div className="col col-8 col-sm-9 mt-2 mb-2">
                <h5>
                  <Link to={'/child/' + adoption.child.fullNumber}>
                    {adoption.child.name}
                  </Link>{' '}
                  ({adoption.child.fullNumber})
                </h5>
                <p className="card-text">
                  <strong>Platební metoda:</strong>{' '}
                  {adoption.paymentInterval.treaty}
                </p>
                <p className="card-text">
                  <strong>Další platba:</strong>{' '}
                  {formatDate(adoption.nextPayment)}
                </p>
                <p className="card-text">
                  <strong>Certifikát:</strong>
                  <AdoptionCertificateLink
                    key={adoption.id + adoption.certificateName}
                    adoption={adoption}
                  ></AdoptionCertificateLink>
                </p>
              </div>
            </div>
          </Fragment>
        ))}
      </div>
    </>
  );
}
