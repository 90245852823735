import React, { useContext, useState } from 'react';
import { Button, Col, Container, Navbar, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faUser } from '@fortawesome/free-solid-svg-icons';
import NavigationBarContent from './NavigationBarContent';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';

export default function NavigationBar() {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const text = searchParams.get('text') || '';

  const { username, email, isLoggedIn } = useContext(UserContext);

  const [searchText, setSearchText] = useState<string>(text);

  function gotoProfileDetail() {
    if (isLoggedIn) {
      navigate('/profile');
    } else {
      navigate('/profile/login');
    }
  }

  return (
    <Navbar
      expand="lg"
      style={{ backgroundColor: 'rgb(0,86,0)', borderBottom: 'solid 3px lime' }}
    >
      <Container>
        <Navbar.Brand href="#home">Menu</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <NavigationBarContent />
          </Nav>
        </Navbar.Collapse>
        <Form method="get" action={'/children/search'}>
          <Row>
            <Col xs="6" lg="9">
              <Form.Control
                type="text"
                name="text"
                placeholder="Vyhledávání"
                className="mr-0"
                value={searchText}
                onChange={(event) => setSearchText(event.target.value)}
              />
            </Col>
            <Col>
              <Button type="submit">
                <FontAwesomeIcon icon={faSearch} />
              </Button>
            </Col>
          </Row>
        </Form>
        <Button type="button" onClick={gotoProfileDetail}>
          <FontAwesomeIcon icon={faUser} />
          <span className="small">
            &nbsp;&nbsp;
            {isLoggedIn ? (username ? username : email) : 'Přihlásit se'}
          </span>
        </Button>
      </Container>
    </Navbar>
  );
}
