import React from 'react';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import Pagination from '../components/Pagination';
import News from '../components/News';

export interface INewsItem {
  id: number;
  title: string;
  content: string;
  createdAt: string;
  teaser: string;
  slug: string;
  mainPhoto: string;
}

export interface INewsItemNode {
  node: INewsItem;
}

const GET_NEWS_LIST = gql`
  query {
    newsList {
      edges {
        node {
          id
          title
          slug
          content
          createdAt
          teaser
          mainPhoto
        }
      }
    }
  }
`;

export default function NewsList() {
  const itemsPerPage = 5;

  const { page: currentPage = 1 } = useParams();
  const pageNumber = Number(currentPage) - 1;
  const navigate = useNavigate();
  const [itemOffset, setItemOffset] = useState(pageNumber * itemsPerPage);

  const { loading, error, data } = useQuery(GET_NEWS_LIST);

  if (loading) return <p>Nahrává se...</p>;
  if (error) return <p>Chyba: {error.message}</p>;

  const news = data.newsList.edges;
  if (news === null) {
    return <p>Chyba při načítání dat.</p>;
  }

  const endOffset = itemOffset + itemsPerPage;
  const currentItems = news.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(news.length / itemsPerPage);

  const handlePageClick = (event: { selected: number }) => {
    const newOffset = (event.selected * itemsPerPage) % news.length;
    setItemOffset(newOffset);
    navigate(`/news/page/` + (Number(event.selected) + 1));
  };

  return (
    <div>
      <h3>Novinky</h3>
      <Pagination
        handlePageClick={handlePageClick}
        pageCount={pageCount}
        pageNumber={pageNumber}
        pageUrlPrefix={`/news/page`}
      ></Pagination>
      <div>
        {currentItems.map((newsItemNode: INewsItemNode) => (
          <div className="" key={newsItemNode.node.id}>
            <News key={newsItemNode.node.id} data={newsItemNode.node} />
          </div>
        ))}
      </div>
      <div className="pt-2">
        <Pagination
          handlePageClick={handlePageClick}
          pageCount={pageCount}
          pageNumber={pageNumber}
          pageUrlPrefix={`/news/page`}
        ></Pagination>
      </div>
    </div>
  );
}
