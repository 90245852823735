import React from 'react';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import Pagination from '../../components/Pagination';
import ImpersonationButton from '../../components/Impersonation/Impersonation';

export interface IUserAdoption {
  id: string;
  child: {
    name: string;
    fullNumber: string;
  };
}

export interface IUserItem {
  id: string;
  username?: string;
  email: string;
  adoptions: IUserAdoption[];
}

export interface IUserItemNode {
  node: IUserItem;
}

const GET_USERS_LIST = gql`
  query {
    usersList {
      edges {
        node {
          id
          email
          username
          adoptions {
            id
            child {
              name
              fullNumber
            }
          }
        }
      }
    }
  }
`;

export default function BackofficeUsersList() {
  const itemsPerPage = 30;

  const { page: currentPage = 1 } = useParams();
  const pageNumber = Number(currentPage) - 1;
  const navigate = useNavigate();
  const [itemOffset, setItemOffset] = useState(pageNumber * itemsPerPage);

  const { loading, error, data } = useQuery(GET_USERS_LIST);

  if (loading) return <p>Nahrává se...</p>;
  if (error) return <p>Chyba: {error.message}</p>;

  const news = data.usersList.edges;
  if (news === null) {
    return <p>Chyba při načítání dat.</p>;
  }

  const endOffset = itemOffset + itemsPerPage;
  const currentItems = news.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(news.length / itemsPerPage);

  const handlePageClick = (event: { selected: number }) => {
    const newOffset = (event.selected * itemsPerPage) % news.length;
    setItemOffset(newOffset);
    navigate(`/backoffice/users/page/` + (Number(event.selected) + 1));
  };

  return (
    <div>
      <h3>Uživatelé</h3>
      <Pagination
        handlePageClick={handlePageClick}
        pageCount={pageCount}
        pageNumber={pageNumber}
        pageUrlPrefix={`/backoffice/users`}
      ></Pagination>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">E-mail</th>
            <th scope="col">Uživatelské jméno</th>
            <th scope="col">Adopce</th>
            <th scope="col">Akce</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((itemNode: IUserItemNode) => (
            <tr key={itemNode.node.id}>
              <th scope="row">{itemNode.node.email}</th>
              <th scope="row">{itemNode.node.username}</th>
              <td>
                {itemNode.node.adoptions.map((adoption: IUserAdoption) => (
                  <span className="badge bg-success m-1" key={adoption.id}>
                    {adoption.child.fullNumber}
                  </span>
                ))}
              </td>
              <td>
                <ImpersonationButton
                  userId={itemNode.node.id}
                ></ImpersonationButton>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="pt-2">
        <Pagination
          handlePageClick={handlePageClick}
          pageCount={pageCount}
          pageNumber={pageNumber}
          pageUrlPrefix={`/backoffice/users`}
        ></Pagination>
      </div>
    </div>
  );
}
