import React from 'react';
import { Link } from 'react-router-dom';
import { INewsItem } from '../pages/NewsList';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import { lgLicenseKey, preparePhotoUrl } from '../tools';
import LightGallery from 'lightgallery/react';

export default function News(props: { data: INewsItem; detail?: boolean }) {
  return (
    <div className={'news' + (props.detail === true ? ' detail' : '')}>
      <h6>
        <Link to={'/news/' + props.data.slug}>{props.data.title}</Link>
      </h6>
      <LightGallery
        speed={500}
        plugins={[lgThumbnail, lgZoom]}
        licenseKey={lgLicenseKey()}
      >
        <a href={preparePhotoUrl(props.data.mainPhoto)}>
          <img
            style={{
              float: 'left',
              maxWidth: '200px',
              maxHeight: '200px',
              width: 'auto',
              height: 'auto',
              margin: '3px 5px 3px 3px',
            }}
            src={
              'https://procontact.fra1.digitaloceanspaces.com/photos/' +
              props.data.mainPhoto
            }
            alt=""
          />
        </a>
      </LightGallery>
      <p
        className="align-top"
        dangerouslySetInnerHTML={{
          __html:
            props.detail === true ? props.data.content : props.data.teaser,
        }}
      ></p>
      <div style={{ clear: 'both' }}></div>
    </div>
  );
}
