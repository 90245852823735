import React from 'react';
import { useParams } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import LightGallery from 'lightgallery/react';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import { lgLicenseKey, preparePhotoUrl } from '../../tools';
import { formatDate } from '../../tools';

const GET_PHOTOGALLERY_DETAIL = gql`
  query photoGallery($slug: String!) {
    photoGallery(slug: $slug) {
      id
      slug
      name
      description
      createdAt
      photos {
        id
        description
        filename
        rotationAngle
      }
    }
  }
`;

export interface IPhoto {
  id: string;
  description: string;
  filename: string;
  rotationAngle: number;
}

export function PhotoGalleryDetail() {
  const { slug } = useParams();

  const { loading, error, data } = useQuery(GET_PHOTOGALLERY_DETAIL, {
    variables: { slug: slug },
  });

  if (loading) return <p>Nahrává se...</p>;
  if (error) return <p>Chyba: {error.message}</p>;

  const gallery = data.photoGallery;

  if (gallery === null) {
    return <p>Chyba při načítání dat.</p>;
  }

  return (
    <div className="page-detail">
      <h5>{gallery.name}</h5>
      <small>{formatDate(gallery.createdAt)}</small>
      <div className="page-content">
        <div className="d-flex flex-wrap">
          <LightGallery
            speed={500}
            plugins={[lgThumbnail, lgZoom]}
            licenseKey={lgLicenseKey()}
          >
            {gallery.photos?.map((photo: IPhoto) => (
              <a key={photo.id} href={preparePhotoUrl(photo.filename)}>
                <img
                  style={{
                    maxWidth: '200px',
                    maxHeight: '200px',
                    width: 'auto',
                    height: 'auto',
                    margin: '3px 5px 3px 3px',
                    border: '3px solid white',
                    rotate: `${photo.rotationAngle}deg`,
                  }}
                  src={preparePhotoUrl(photo.filename)}
                  alt=""
                />
              </a>
            ))}
          </LightGallery>
        </div>
      </div>
    </div>
  );
}
