import { IChild } from './ChildrenList';
import React from 'react';
import { Link } from 'react-router-dom';
import { lgLicenseKey, preparePhotoUrl } from '../tools';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import LightGallery from 'lightgallery/react';

export default function ChildPreview(props: { data: IChild }) {
  return (
    <div className="child">
      <h6>
        <Link to={'/child/' + props.data.fullNumber}>
          {props.data.name} - {props.data.fullNumber}
        </Link>
      </h6>

      {props.data.top && (
        <div
          className="alert alert-danger m-2 text-center fw-bold"
          role="alert"
        >
          Upřednostněná adopce
        </div>
      )}

      <LightGallery
        speed={500}
        plugins={[lgThumbnail, lgZoom]}
        licenseKey={lgLicenseKey()}
      >
        <a href={preparePhotoUrl(props.data.mainPhoto)}>
          <img
            style={{
              float: 'left',
              maxWidth: '200px',
              maxHeight: '200px',
              width: 'auto',
              height: 'auto',
              margin: '3px 5px 3px 3px',
            }}
            src={preparePhotoUrl(props.data.mainPhoto)}
            alt=""
          />
        </a>
      </LightGallery>
      <p
        className="align-top"
        dangerouslySetInnerHTML={{
          __html: props.data.teaser,
        }}
      ></p>
      <div style={{ clear: 'both' }}></div>
    </div>
  );
}
