import React from 'react';
import {
  FieldErrors,
  FieldValues,
  Path,
  UseFormRegister,
} from 'react-hook-form';
import { FormField } from './FormField';

export function ContainerFormField<T extends FieldValues>({
  label,
  type,
  required,
  className,
  name,
  register,
  errors,
}: {
  label: string;
  type: string;
  required?: boolean;
  className?: string;
  name: Path<T>;
  register: UseFormRegister<T>;
  errors: FieldErrors;
}) {
  return (
    <div className="row">
      <div className={`form-group col ${className}`}>
        <FormField
          label={label}
          type={type}
          required={required ? required : false}
          name={name}
          register={register}
          errors={errors}
        ></FormField>
      </div>
    </div>
  );
}
