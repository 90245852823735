import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import Form from 'react-bootstrap/Form';
import ReCAPTCHA from 'react-google-recaptcha';
import { RECAPTCHA_SITE_KEY } from '../../constants';
import { gql, useMutation } from '@apollo/client';

type Inputs = {
  email: string;
};

interface ResetPasswordResponse {
  resetPasswordRequest: {
    success: boolean;
    message: string;
  };
}

const RESET_PASSWORD_MUTATION = gql`
  mutation ResetPasswordRequest($email: String!, $captchaToken: String!) {
    resetPasswordRequest(email: $email, captchaToken: $captchaToken) {
      success
      message
    }
  }
`;

export default function ChangePasswordRequest() {
  const { register, handleSubmit, reset } = useForm<Inputs>();

  const [captchaToken, setCaptchaToken] = useState<string | null>('');

  const [message, setMessage] = useState<string | null>(null);
  const [messageLevel, setMessageLevel] = useState<string>('success');

  const [resetPasswordMutation] = useMutation<ResetPasswordResponse>(
    RESET_PASSWORD_MUTATION,
    {
      onCompleted: (response) => {
        if (!response.resetPasswordRequest.success) {
          setMessage(response.resetPasswordRequest.message);
          setMessageLevel('danger');
        } else {
          setMessage(
            'Odkaz na změnu hesla vám byl odeslán. Pokračujte dále dle instrukcí v emailu.',
          );
          setMessageLevel('success');
          reset();
        }
      },
    },
  );

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    if (captchaToken && data.email)
      await resetPasswordMutation({
        variables: { email: data.email, captchaToken: captchaToken },
      });
  };

  return (
    <div>
      <h3>Zapomenuté heslo</h3>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="container">
          <div className="row">
            <div className="form-group col col-12">
              {message && (
                <div
                  className={`alert alert-${messageLevel} bg-${messageLevel} text-white`}
                  role="alert"
                >
                  {message}
                </div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="form-group col col-4  col-sm-12">
              <Form.Control
                {...register('email', {
                  required: true,
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: 'Zadaná e-mailová adresa není validní.',
                  },
                })}
                type="input"
                name="email"
                placeholder="Zadejte váš e-mail"
                className="mb-2"
              />
            </div>
          </div>
          <div className="row">
            <div className="form-group col col-4 col-sm-12">
              <ReCAPTCHA
                sitekey={RECAPTCHA_SITE_KEY}
                onChange={(token) => setCaptchaToken(token)}
              />
            </div>
          </div>
          <div className="row">
            <div className="form-group col col-4 col-sm-12">
              <input
                type="submit"
                className="btn btn-primary mb-2 mt-2"
                value="Odeslat"
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
