import ReactPaginate from 'react-paginate';
import React from 'react';

interface PaginationProps {
  handlePageClick: (event: { selected: number }) => void;
  pageCount: number;
  pageNumber: number;
  pageUrlPrefix: string;
  pageUrlSuffix?: string;
}

export default function Pagination(props: PaginationProps) {
  let pageUrlSuffix = props.pageUrlSuffix;
  if (props.pageUrlSuffix === undefined) {
    pageUrlSuffix = '';
  }
  return (
    <ReactPaginate
      breakLabel="..."
      nextLabel=">"
      onPageChange={props.handlePageClick}
      pageRangeDisplayed={2}
      marginPagesDisplayed={2}
      pageCount={props.pageCount}
      previousLabel="<"
      renderOnZeroPageCount={null}
      breakClassName="page-item"
      breakLinkClassName="page-link"
      containerClassName="pagination justify-content-center"
      pageClassName="page-item"
      pageLinkClassName="page-link"
      previousClassName="page-item"
      previousLinkClassName="page-link"
      nextClassName="page-item"
      nextLinkClassName="page-link"
      forcePage={props.pageNumber}
      activeClassName="active"
      hrefBuilder={(page, pageCount) =>
        page >= 1 && page <= pageCount
          ? `${props.pageUrlPrefix}/${page}${pageUrlSuffix}`
          : ''
      }
      hrefAllControls
    />
  );
}
