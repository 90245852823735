export const txTypeToString = (type: string) => {
  if (type == 'ADOPTION') {
    return 'Adopce dítěte';
  } else if (type === 'DONATION') {
    return 'Finanční dar';
  } else if (type === 'GIFT') {
    return 'Platba na dárek';
  } else {
    return 'Ostatní';
  }
};

export const statusToString = (status: string) => {
  if (status === 'UNACCOUNTED') {
    return 'Nezaúčtováno';
  } else if (status === 'ACCOUNTED') {
    return 'Zaúčtováno';
  } else if (status === 'CANCELED') {
    return 'Stornováno';
  }
};
