import React, { createContext, useEffect, useState } from 'react';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { AUTH_ADMIN_TOKEN, AUTH_TOKEN } from '../constants';
import { useNavigate } from 'react-router-dom';

export const GET_USER_INFO = gql`
  query QueryUserInfo {
    me {
      username
      email
      phone
      role
    }
  }
`;

export const USER_LOGIN = gql`
  mutation Login($username: String!, $password: String!) {
    login(data: { username: $username, password: $password }) {
      user {
        username
        email
        phone
        role
      }
      accessToken
    }
  }
`;

interface IUserResponse {
  me: {
    username: string;
    email: string;
    phone: string;
    role: string;
  };
}

const defaultValue = {
  username: 'guest',
  email: '',
  phone: '',
  role: 'user',
};

export interface IUserDetails {
  username: string;
  email: string;
  phone: string;
  role: string;
}

export interface IUserContext {
  username: string;
  email: string;
  phone: string;
  role: string;
  isLoggedIn?: boolean;
  login?: (username: string, password: string) => Promise<void>;
  logout?: () => void;
  userToken?: string | null;
  setUserToken?: (token: string | null) => void;
  adminToken?: string | null;
  setAdminToken?: (token: string | null) => void;
  impersonateAsUser?: (userId: string) => void;
  stopImpersonate?: () => void;
}

interface ILoginResponse {
  login: {
    user: { username: string; email: string; phone: string; role: string };
    accessToken: string;
  };
}

interface ImpersonateResponse {
  getTokenForImpersonation: {
    accessToken: string;
  };
}

export const IMPERSONATE_AS_USER = gql`
  mutation Impersonation($userId: String!) {
    getTokenForImpersonation(userId: $userId) {
      accessToken
    }
  }
`;

export const UserContext = createContext<IUserContext>(defaultValue);

export function UserProvider({ children }: { children: React.ReactNode }) {
  const [userToken, setUserToken] = useState<string | null>(
    localStorage.getItem(AUTH_TOKEN),
  );
  const [adminToken, setAdminToken] = useState<string | null>(
    localStorage.getItem(AUTH_ADMIN_TOKEN),
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (adminToken !== null) {
      localStorage.setItem(AUTH_ADMIN_TOKEN, adminToken);
    } else {
      localStorage.removeItem(AUTH_ADMIN_TOKEN);
    }
    if (userToken !== null) {
      localStorage.setItem(AUTH_TOKEN, userToken);
    } else {
      localStorage.removeItem(AUTH_TOKEN);
    }
  }, [userToken, adminToken]);

  const [userDetails, setUserDetails] = useState(defaultValue);

  const [impersonateMutation, { data: impersonateData }] =
    useMutation<ImpersonateResponse>(IMPERSONATE_AS_USER);

  const impersonateAsUser = async (userId: string) => {
    await impersonateMutation({ variables: { userId } });
  };

  const stopImpersonate = () => {
    setAdminToken(null);
    setUserToken(adminToken);
    if (userDetails.role === 'ADMIN') {
      window.location.href = '/backoffice';
    } else {
      window.location.href = '/profile';
    }
  };

  const // eslint-disable-next-line @typescript-eslint/no-unused-vars
    [_, { refetch, loading, error, data: userDetailsResponse }] =
      useLazyQuery<IUserResponse>(GET_USER_INFO);

  useEffect(() => {
    refetch().catch(console.error);
  }, [userToken, adminToken]);

  useEffect(() => {
    const impersonateUserToken =
      impersonateData?.getTokenForImpersonation.accessToken;

    if (
      userToken &&
      userDetails &&
      userDetails.role === 'ADMIN' &&
      adminToken === null &&
      impersonateUserToken
    ) {
      setAdminToken(userToken);
      setUserToken(impersonateUserToken);
    }
  }, [impersonateData]);

  const [runLoginMutation, { data: loginResponse }] =
    useMutation<ILoginResponse>(USER_LOGIN);

  const login = async (username: string, password: string) => {
    await runLoginMutation({ variables: { username, password } });
  };

  const logout = () => {
    setUserDetails({ ...defaultValue });
    setUserToken(null);
    setAdminToken(null);
    navigate('/');
  };

  useEffect(() => {
    if (userDetailsResponse && userDetailsResponse.me) {
      setUserDetails({
        ...userDetailsResponse.me,
      });
    }
  }, [userDetailsResponse]);

  useEffect(() => {
    if (loginResponse && loginResponse.login) {
      setUserDetails({
        ...loginResponse.login.user,
      });
      setUserToken(loginResponse.login.accessToken);
      window.location.href = '/profile';
    }
  }, [loginResponse]);

  if (loading) return <span>Loading...</span>;

  return (
    <UserContext.Provider
      value={{
        ...userDetails,
        isLoggedIn: userDetails.username !== 'guest',
        login,
        logout,
        userToken,
        setUserToken,
        adminToken,
        setAdminToken,
        impersonateAsUser,
        stopImpersonate,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export const useUser = () => React.useContext(UserContext);
