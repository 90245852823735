import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { formatDate, lgLicenseKey, preparePhotoUrl } from '../../tools';
import LightGallery from 'lightgallery/react';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';

const GET_PHOTOGALLERY_LIST = gql`
  query {
    galleriesList {
      edges {
        node {
          id
          name
          slug
          description
          createdAt
          photos {
            id
            description
            filename
            rotationAngle
          }
        }
      }
    }
  }
`;

interface IGalleryNode {
  node: {
    id: string;
    name: string;
    slug: string;
    description: string;
    createdAt: string;
    photos: {
      id: string;
      description: string;
      filename: string;
      rotationAngle: number;
    }[];
  };
}

export function PhotoGalleryList() {
  const { slug } = useParams();

  const { loading, error, data } = useQuery(GET_PHOTOGALLERY_LIST, {
    variables: { slug: slug },
  });

  if (loading) return <p>Nahrává se...</p>;
  if (error) return <p>Chyba: {error.message}</p>;

  const galleries = data.galleriesList.edges;

  if (galleries === null) {
    return <p>Chyba při načítání dat.</p>;
  }

  return (
    <div className="page-detail">
      <h5>Fotogalerie</h5>
      <div className="page-content">
        {galleries.map((gallery: IGalleryNode) => (
          <div key={gallery.node.id} className="container">
            <div className="row">
              <div className="col col-1">
                <LightGallery
                  speed={500}
                  plugins={[lgThumbnail, lgZoom]}
                  licenseKey={lgLicenseKey()}
                >
                  {gallery.node.photos[0] && (
                    <a
                      key={gallery.node.photos[0].id}
                      href={preparePhotoUrl(gallery.node.photos[0].filename)}
                    >
                      <img
                        style={{
                          maxWidth: '70px',
                          maxHeight: '70px',
                          width: 'auto',
                          height: 'auto',
                          margin: '3px 5px 3px 3px',
                          border: '3px solid white',
                          rotate: `${gallery.node.photos[0].rotationAngle}deg`,
                        }}
                        src={preparePhotoUrl(gallery.node.photos[0].filename)}
                        alt=""
                      />
                    </a>
                  )}
                </LightGallery>
              </div>
              <div className="col col-11">
                <Link to={'/photogallery/' + gallery.node.slug}>
                  {gallery.node.name}
                </Link>
                <p>{formatDate(gallery.node.createdAt)}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
