import React from 'react';
import AdoptionOffer from './menu_blocks/AdoptionOffer';
import AdoptionSummary from './menu_blocks/AdoptionSummary';
import SupportUsMenu from './menu_blocks/SupportUs';

export default function LeftMenu() {
  return (
    <div className="pmenu">
      <AdoptionSummary></AdoptionSummary>
      <AdoptionOffer></AdoptionOffer>
      <SupportUsMenu></SupportUsMenu>
    </div>
  );
}
