import React from 'react';
import { Link } from 'react-router-dom';

export default function SupportUsMenu() {
  return (
    <div className="menu-block">
      <h5>Podpořte nás</h5>
      <p>
        Vyberte si banner, který chcete vložit do Vašich stránek.{' '}
        <Link to="/support-us">Podporují nás</Link>
      </p>
    </div>
  );
}
