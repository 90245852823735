import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

export default function Header() {
  // 0...6
  const [index, setIndex] = useState<number>(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setIndex(Math.floor(Math.random() * 6));
    }, 20000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div style={{ position: 'relative' }}>
      <a
        style={{ position: 'absolute', right: '5px', bottom: '5px' }}
        href="https://www.facebook.com/procontact.cz"
      >
        <img
          style={{ width: 'calc(30px)' }}
          src="/images/fb_small.png"
          alt="proContact facebook"
        />
      </a>
      <Link to="/">
        <img
          src={`/images/header${index}.jpg`}
          alt=""
          style={{ width: '100%' }}
        />
      </Link>
    </div>
  );
}
