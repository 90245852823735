import React from 'react';
import 'easymde/dist/easymde.min.css';
import { gql, useQuery } from '@apollo/client';
import EmailTemplateDetail from './EmailTemplateDetail';

export interface IEmailTemplate {
  id: string;
  type: string;
  subject: string;
  body: string;
}

const GET_EMAIL_TEMPLATES = gql`
  query EmailTemplates {
    emailTemplates {
      id
      type
      subject
      body
    }
  }
`;

export default function BackofficeEmailTemplatesList() {
  const { loading, error, data } = useQuery(GET_EMAIL_TEMPLATES);

  if (loading) return <p>Nahrává se...</p>;
  if (error) return <p>Chyba: {error.message}</p>;

  const emailTemplates = data.emailTemplates;
  if (emailTemplates === null) {
    return <p>Chyba při načítání dat.</p>;
  }

  return (
    <>
      <h3>Šablony emailů</h3>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Typ</th>
            <th scope="col">Předmět</th>
            <th scope="col">Akce</th>
          </tr>
        </thead>
        <tbody>
          {emailTemplates.map((template: IEmailTemplate) => (
            <tr key={template.id}>
              <th scope="row">{template.type}</th>
              <th scope="row">{template.subject}</th>
              <th scope="row">
                <EmailTemplateDetail
                  id={template.id}
                  type={template.type}
                  body={template.body}
                  subject={template.subject}
                ></EmailTemplateDetail>
              </th>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}
