import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { gql, useLazyQuery } from '@apollo/client';

const GET_RESULT = gql`
  query AdminSearchUserEmail($childNumbers: String!) {
    adminSearchUserEmail(data: $childNumbers)
  }
`;

interface IResult {
  adminSearchUserEmail: string;
}

export default function BackofficeUserEmailSearch() {
  const [result, setResult] = useState<string>('');
  const [batch, setBatch] = useState<string>('');

  const [getResult] = useLazyQuery<IResult>(GET_RESULT);

  const onClick = async () => {
    await getResult({
      variables: { childNumbers: batch },
      onCompleted: (data) => {
        setResult(data.adminSearchUserEmail);
      },
    });
  };

  return (
    <div>
      <h3>Výpis emailů</h3>

      <div className="form-group">
        <label htmlFor="seznamDeti">Seznam čísel dětí</label>
        <textarea
          className="form-control"
          id="seznamDeti"
          rows={10}
          value={batch}
          onChange={(e) => {
            setBatch(e.target.value);
          }}
        ></textarea>
        <span className="form-text text-white">
          Vkládejte čísla dětí oddělené klávesou ENTER.
        </span>
      </div>

      <div className="form-group mt-3">
        <h4>Výsledek</h4>
        <textarea
          className="form-control"
          id="vysledek"
          rows={5}
          value={result}
          readOnly={true}
        ></textarea>
      </div>

      <div className="pt-3 pb-3">
        <Button onClick={onClick} variant="primary">
          Odeslat
        </Button>
      </div>
    </div>
  );
}
