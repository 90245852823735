import React from 'react';
import Form from 'react-bootstrap/Form';
import { ErrorMessage } from '@hookform/error-message';
import {
  FieldErrors,
  FieldValues,
  Path,
  UseFormRegister,
} from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';

export function FormField<T extends FieldValues>({
  label,
  type = 'input',
  required = false,
  name,
  register,
  errors,
}: {
  label: string;
  type: string;
  required: boolean;
  name: Path<T>;
  register: UseFormRegister<T>;
  errors: FieldErrors;
}) {
  return (
    <>
      {type === 'input' && (
        <Form.Label>{required ? <strong>{label}</strong> : label}:</Form.Label>
      )}
      {type === 'input' && required && (
        <>
          &nbsp;
          <FontAwesomeIcon
            className="fa-sm"
            title="Toto pole je povinné."
            icon={faAsterisk}
          />
        </>
      )}
      {type === 'input' ? (
        <Form.Control
          {...register(name, {
            required: required ? 'Toto pole je povinné.' : false,
          })}
          type={type}
          name={name}
          className="mb-2"
        />
      ) : (
        <Form.Check
          {...register(name)}
          type="checkbox"
          label={label}
          className="mb-2"
          name={name}
        />
      )}
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ messages }) =>
          messages &&
          Object.entries(messages).map(([type, message]) => (
            <div
              key={type}
              className={`alert alert-danger bg-danger text-white`}
              role="alert"
            >
              {message}
            </div>
          ))
        }
      />
    </>
  );
}
